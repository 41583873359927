import TextComponent from '@pretto/bricks/components/typography/Text'

import PropTypes from 'prop-types'

import * as S from '../styles'

const Text = ({ children }) => (
  <S.Block>
    <TextComponent type="p">{children}</TextComponent>
  </S.Block>
)

Text.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Text
